import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { LoaderMain } from 'src/components/loader-main';

import './styles/index.scss';

import './polyfills';

const App = lazy(() => import('./app'));

const rootElement = document?.getElementById('root');

const LOADER_ANIMATION_DURATION = 4000;

const RootComponent = () => {
    const [isDelayComplete, setIsDelayComplete] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsDelayComplete(true), LOADER_ANIMATION_DURATION);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {!isDelayComplete && (
                <div style={{ zIndex: 100000, position: 'fixed' }}>
                    <LoaderMain />
                </div>
            )}

            <Suspense fallback={null}>
                <App />
            </Suspense>
        </div>
    );
};

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <RootComponent />
        </React.StrictMode>,
    );
}
