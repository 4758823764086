import { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import airplaneMainPreloader from 'src/assets/animate/airplane-main-preloader.json';
import logoMainPreloaderDark from 'src/assets/animate/logo-main-preloader-dark.json';
import logoMainPreloaderLight from 'src/assets/animate/logo-main-preloader-light.json';

import styles from './index.module.scss';

export const LoaderMain = () => {
    const [isMainAnimationCompleted, setIsMainAnimationCompleted] = useState(false);
    const isThemeLight = window.ExternalVendorJsInterface?.isThemeLight() === 'true';

    const logoAnimationData = isThemeLight ? logoMainPreloaderLight : logoMainPreloaderDark;

    const logoContainerRef = useRef<HTMLDivElement | null>(null);
    const airplaneContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!logoContainerRef.current) return;

        const logoAnimation = lottie.loadAnimation({
            container: logoContainerRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: logoAnimationData,
        });

        logoAnimation.addEventListener('complete', () => {
            setIsMainAnimationCompleted(true);
        });

        return () => logoAnimation.destroy();
    }, [logoAnimationData]);

    useEffect(() => {
        if (isMainAnimationCompleted && airplaneContainerRef.current) {
            const airplaneAnimation = lottie.loadAnimation({
                container: airplaneContainerRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: airplaneMainPreloader,
            });

            return () => airplaneAnimation.destroy();
        }
    }, [isMainAnimationCompleted]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <div ref={logoContainerRef} />
            </div>
            {isMainAnimationCompleted && (
                <div className={styles.logo} style={{ zIndex: 0 }}>
                    <div ref={airplaneContainerRef} />
                </div>
            )}
        </div>
    );
};
